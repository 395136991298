@use "sass:meta" as ---j8cc94mk1hd;.main-v2-catalog {
  @include breakpoint(small only) {
    margin-top: 1rem;
  }

  .wrapper {
    background-color: $alice-blue;
    padding: 30px 20px 20px;

    @include breakpoint(small only) {
      padding-top: 20px;
    }

    h2 {
      font-size: rem-calc(30);
      margin-bottom: 1rem;

      @include breakpoint(small only) {
        font-size: rem-calc(26);
      }
    }

    .catalog {
      &_category {
        .list {
          ul {
            list-style: none;
            margin-left: 0;
            padding-right: 1rem;

            li {
              margin-bottom: .9rem;

              a {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                span {
                  font-size: rem-calc(13);
                  line-height: 1.4;
                  color: $dark-gray;
                }
              }
            }
          }
        }
      }

      &_inform {
        @include breakpoint(large) {
          margin-left: 2rem;
        }

        .cell:first-child {
          margin-bottom: .6rem;

          h3 {
            font-size: rem-calc(24);
            padding-top: .5rem;
          }
        }

        &-week {
          .item {
            background-color: $white;
            padding: 1.2rem;
            transition: all .2s;

            &:hover {
              box-shadow: 0 3px 7px 0 #3131312e;
            }

            .logo {
              @include breakpoint(medium only) {
                width: 100%;
                display: flex;
                justify-content: center;
              }

              img,
              svg {
                display: block;
                object-fit: contain;
                width: 100%;
                height: 120px;

                @include breakpoint(medium only) {
                  width: 55%;
                }
              }
            }

            .name {
              margin-top: .8rem;
              font-weight: $global-weight-bold;
              -webkit-line-clamp: 2;

              @include breakpoint(medium only) {
                font-size: rem-calc(20);
              }
            }

            .desc {
              font-size: rem-calc(14);
              margin-top: .4rem;
              color: $black;
              -webkit-line-clamp: 7;
            }
          }
        }

        &-new {
          h3 {
            font-size: rem-calc(21);
            padding-top: .5rem;
          }

          .items {
            margin-top: 1.5rem;

            .item {
              background-color: $white;
              padding: .3rem 1.2rem;
              display: flex;
              align-items: center;
              transition: all .2s;

              &:hover {
                box-shadow: 0 3px 7px 0 #3131312e;
              }

              .logo {
                line-height: 0;
                width: 80px;
                flex-shrink: 0;

                svg {
                  height: 80px;
                  width: 100%;
                }
              }

              .info {
                margin-left: 1rem;

                .title {
                  font-weight: $global-weight-bold;

                  i {
                    margin-left: .4rem;
                  }
                }

                .geo {
                  font-size: rem-calc(13);
                  color: $dark-gray;
                }
              }
            }
          }
        }

        .add {
          margin-top: 1rem;
          padding: 1.4rem;
          font-size: $global-font-size;
          font-weight: $global-weight-bold;
        }
      }
    }
  }
}

;@include ---j8cc94mk1hd.load-css("sass-embedded-legacy-load-done:3752");