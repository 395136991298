@use "sass:meta" as ---j8cc94mk1hd;.main-v2-magazine {
  position: relative;

  @include breakpoint(small only) {
    margin-top: 2.5rem;
  }

  .wrapper {
    @include breakpoint(small only) {
      justify-content: center;
    }

    @include breakpoint(medium) {
      border-left: 80px solid $alice-blue;
      padding-bottom: 5rem;
    }

    .magazine {
      &_nomer {
        @include breakpoint(small only) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        @include breakpoint(medium only) {
          order: 2;
          margin-left: 2rem;
          margin-top: 2rem;
          display: flex;
        }

        @include breakpoint(large) {
          position: absolute;
          top: 40px;
          left: 60px;
        }

        .tizer {
          font-size: rem-calc(30);
          font-weight: $global-weight-bold;
        }

        img {
          width: 265px;
          margin-top: 1rem;
          box-shadow: 0 .025rem .5125rem #00000061;

          @include breakpoint(medium only) {
            width: 200px;
          }
        }

        .actions {
          width: 230px;
          margin-top: 1.5rem;
          display: flex;
          flex-direction: column;

          .button {
            font-size: $global-font-size;
            font-weight: $global-weight-bold;
            padding: 1rem;
          }

          .link {
            align-self: center;
            color: $black;
          }

          @include breakpoint(medium only) {
            align-self: center;
            margin-left: 2rem;
          }
        }
      }

      &_publications {
        @include breakpoint(medium only) {
          order: 1;
        }

        margin-top: 3rem;

        .article {
          display: flex;
          align-items: center;
          margin: 1rem 0;

          &_title {
            flex-grow: 1;
            padding-right: 1rem;
            font-size: rem-calc(15);
            font-weight: $global-weight-bolder;

            @include breakpoint(medium) {
              margin-left: 2rem;
              padding-right: 1rem;
            }

            @include breakpoint(medium down) {
              font-size: rem-calc(14);
            }

            -webkit-line-clamp: 3;
          }

          img {
            width: 120px;
            aspect-ratio: 3/2;
            object-fit: cover;
          }
        }
      }
    }
  }

  &__subscribe {
    a {
      color: map-get($foundation-palette, san-marino);
      font-weight: $global-weight-bolder;
      text-decoration: underline;
    }
  }
}

;@include ---j8cc94mk1hd.load-css("sass-embedded-legacy-load-done:3766");