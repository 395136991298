@use "sass:meta" as ---ry84wxtmkt8;.main-v2-calendar {
  margin-top: 2rem;

  .title {
    display: flex;

    h2 {
      font-size: rem-calc(30);
      margin: .6rem 0;

      @include breakpoint(small only) {
        font-size: rem-calc(26);
      }
    }

    &::after {
      content: '';
      display: block;
      flex-grow: 1;
      margin-left: 2rem;
      background-color: $alice-blue;
    }
  }

  .wrapper {
    @include breakpoint(medium) {
      margin-top: 2rem;
    }

    ul {
      list-style: none;
      margin: 0;

      li {
        @include breakpoint(small only) {
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
          border-bottom: 1px solid $medium-gray;
        }

        @include breakpoint(medium) {
          &:not(:first-child, :last-child) {
            border-top: 1px solid $medium-gray;
            border-bottom: 1px solid $medium-gray;
            padding: .8rem 0;
          }

          &:first-child {
            padding-bottom: .8rem;
          }

          &:last-child {
            padding-top: .8rem;
          }
        }

        .event {
          display: flex;
          align-items: center;
          gap: 10px;

          @include breakpoint(medium only) {
            display: block;
            min-height: 100px;
          }

          &:hover {
            .event_title {
              color: #415b8c;
            }
          }

          &_date {
            display: flex;
            align-items: center;
            font-size: $global-font-size;
            line-height: 1.3;
            width: 140px;
            flex-shrink: 0;
            color: $black;

            @include breakpoint(medium only) {
              font-size: rem-calc(14);
            }

            span {
              display: block;
              font-size: rem-calc(45);
              font-weight: $global-weight-bold;
              text-align: right;
              margin-right: .6rem;
              color: map-get($foundation-palette, primary);
              flex-shrink: 0;

              @include breakpoint(medium only) {
                font-size: rem-calc(40);
              }
            }
          }

          &_title {
            font-size: rem-calc(18);
            font-weight: $global-weight-bold;
            line-height: 1.3;
            -webkit-line-clamp: 2;

            @include breakpoint(medium only) {
              font-size: $global-font-size;
            }
          }
        }
      }
    }

    @include breakpoint(small only) {
      .cell:nth-child(3) {
        li:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

;@include ---ry84wxtmkt8.load-css("sass-embedded-legacy-load-done:3729");