@use "sass:meta" as ---3m2zn08l3uw;.main-v2 {
  h2,
  h3 {
    color: $black;

    a:hover {
      color: $black;
    }
  }

  a {
    color: $black;

    &:hover {
      color: #405b8c;
    }
  }

  .crop {
    overflow: hidden;
    display: -webkit-box; /* stylelint-disable-line */
    -webkit-box-orient: vertical;
  }
}

;@include ---3m2zn08l3uw.load-css("sass-embedded-legacy-load-done:3741");