@use "sass:meta" as ---wv8raye81n;.main-v2-actual {
  margin-top: 2rem;

  a {
    color: $black;
  }

  .publications {
    @include breakpoint(medium only) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    ul {
      font-size: $global-font-size;
      list-style: none;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        line-height: 1.4;

        span {
          font-size: rem-calc(60);
          font-weight: $global-weight-bold;
          margin-right: 1.5rem;
          color: $tangerine-yellow;
        }
      }
    }
  }

  .library {
    @include breakpoint(small only) {
      margin-top: 3rem;
    }

    ul {
      font-size: $global-font-size;
      list-style: none;
      margin: 0;

      li {
        padding: 9px 0;
      }
    }
  }

  h2,
  h3 {
    font-size: rem-calc(24);
    padding-top: .5rem;
  }
}

;@include ---wv8raye81n.load-css("sass-embedded-legacy-load-done:3700");