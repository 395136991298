@use "sass:meta" as ---rj7841chyf;.main-v2-publications {
  margin-top: 2rem;

  h2 {
    font-size: rem-calc(30);
    font-weight: $global-weight-bold;

    @include breakpoint(small only) {
      font-size: rem-calc(26);
    }
  }

  .cell {
    margin-top: 2rem;

    a {
      &:hover {
        .content {
          .title {
            color: map-get($foundation-palette, san-marino);
          }
        }
      }
    }

    .article {
      overflow: hidden;

      .img {
        min-height: 220px;
        z-index: 1;

        img {
          max-height: 220px;
          width: 100%;
          object-fit: cover;
        }
      }

      .content {
        padding: 15px 15px 30px;
        z-index: 3;
        margin: -40px 20px 0;
        position: relative;
        background-color: $white;

        .rubric {
          margin-top: -40px;
          margin-left: -15px;
          margin-right: -15px;

          span {
            color: $white;
            padding: 5px 15px;
            font-size: rem-calc(13);
            font-weight: $global-weight-bold;

            &.business {
              background-color: map-get($foundation-palette, primary);
            }

            &.tech {
              background-color: map-get($foundation-palette, warning);
            }

            &.diy {
              background-color: map-get($foundation-palette, success);
            }
          }
        }

        .title {
          color: $black;
          font-size: rem-calc(18);
          font-weight: $global-weight-bold;
          margin-top: 1rem;
          -webkit-line-clamp: 2;
        }

        .desc {
          color: $black;
          margin-top: 1rem;
          font-size: rem-calc(15);
          -webkit-line-clamp: 5;
        }
      }
    }
  }

  .more {
    margin-top: 2rem;
    background-color: $alice-blue;
    border-radius: 0;
    font-size: $global-font-size;
    font-weight: $global-weight-bold;
    padding: 1.2rem;
    margin-bottom: 0;
    transition: all .2s;

    &:hover {
      background-color: #d2d8e1;
    }
  }
}

;@include ---rj7841chyf.load-css("sass-embedded-legacy-load-done:3777");