@use "sass:meta" as ---3m2zn08l3uw;.main-v2-news {
  &_top {
    .main-news {
      @include xy-cell-gutters($gutter-position: top bottom, $breakpoint: 'auto');

      .space-between {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
      }

      &_item {
        .title {
          font-weight: $global-weight-bold;
          line-height: 1.3;

          &.h3 {
            -webkit-line-clamp: 3;
          }

          &.h4 {
            -webkit-line-clamp: 4;
          }

          @include breakpoint(small only) {
            &.h4 {
              font-size: rem-calc(16);
            }
          }

          &.h3 + .intro.crop {
            -webkit-line-clamp: 3;
          }

          &.h4 + .intro.crop {
            -webkit-line-clamp: 5;

            @include breakpoint (small only) {
              -webkit-line-clamp: 3;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        .intro {
          font-size: rem-calc(14);
          color: $black;
        }

        img {
          aspect-ratio: 8/5;
          object-fit: cover;
          width: 100%;

          @include xy-gutters($gutter-position: bottom);
        }
      }
    }

    .banners {
      @include breakpoint(small only) {
        flex-direction: row;
        justify-content: space-around;
        margin-top: 0;

        #bs51 {
          margin-bottom: 0;
        }

        #bs52,
        #bs53 {
          display: none;
        }
      }

      @include breakpoint(medium only) {
        flex-direction: row;
        justify-content: space-around;

        #bs51 {
          display: none;
        }

        #bs52,
        #bs53 {
          margin-bottom: 0;
        }
      }

      @include breakpoint(large) {
        align-items: flex-end;
        width: 240px;
      }

      &__bottom {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;

        @include breakpoint(small only) {
          justify-content: space-around;
          margin-top: 2rem;

          #bs11 {
            max-width: 234px;
            max-height: 90px;
          }

          #bs3 {
            display: none;
          }
        }

        @include breakpoint(medium only) {
          display: none;
        }

        @include breakpoint(large only) {
          #bs11 {
            width: 177px;
            height: 69px;
          }

          #bs3 {
            width: 491px;
            height: 70px;
          }
        }

        @include breakpoint(xlarge) {
          #bs11 {
            width: 234px;
            height: 90px;
          }

          #bs3 {
            width: 650px;
            height: 90px;
          }
        }
      }
    }

    #bs51,
    #bs52 {
      @include grid-column-margin;
    }
  }

  &_feed {
    margin-top: 2rem;

    h2 {
      font-size: rem-calc(30);

      @include xy-gutters($gutter-position: bottom);

      @include breakpoint(small only) {
        font-size: rem-calc(26);
      }
    }

    .feed-news {
      .cell {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .announce {
        background-color: #edf2f5;
        padding: $global-padding;
        border-radius: $global-radius;

        @include xy-gutters($gutter-position: top bottom);

        img {
          aspect-ratio: 3/2;
          object-fit: cover;
        }

        div {
          font-weight: $global-weight-bold;
          font-size: rem-calc(15);
          margin-top: 1rem;
          -webkit-line-clamp: 3;
        }
      }

      &_item {
        @include xy-gutters($gutter-position: top bottom);

        .image {
          margin-bottom: .3rem;

          img {
            aspect-ratio: 3/2;
            object-fit: cover;
          }
        }

        .info {
          display: flex;
          font-size: rem-calc(13);
          color: $dark-gray;

          .date {
            flex-shrink: 0;
          }

          .date::after {
            content: '|';
            display: inline;
            margin: 0 .5rem;
          }

          .rubric {
            -webkit-line-clamp: 1;
          }
        }

        .title {
          font-size: rem-calc(15);
          margin-top: .3rem;
          -webkit-line-clamp: 4;
          font-weight: $global-weight-bolder;
        }
      }

      &_more {
        margin-top: 2rem;
        background-color: $alice-blue;
        border-radius: 0;
        font-size: $global-font-size;
        font-weight: $global-weight-bold;
        padding: 1.2rem;
        margin-bottom: 0;
        transition: all .2s;

        &:hover {
          background-color: #d2d8e1;
        }
      }
    }

    .lme {
      margin-top: 1.7rem;

      a {
        color: map-get($foundation-palette, primary);
      }

      h3 {
        font-weight: $global-weight-bold;
      }

      table {
        border: 1px solid $light-gray;
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        margin-bottom: 6px;
      }

      thead {
        tr {
          background-color: $light-gray;
          font-size: rem-calc(11);

          th:first-child {
            text-align: left;
          }

          th {
            text-align: right;
            font-weight: $global-weight-normal;
            padding: inherit;
          }
        }
      }

      tr {
        font-size: rem-calc(14);
        padding: 5px 14px;

        td {
          font-weight: $global-weight-normal;
          padding: inherit;
        }

        .c2,
        .c3 {
          text-align: right;

          &.up {
            font-weight: $global-weight-bold;
            color: map-get($foundation-palette, success);
          }

          &.down {
            font-weight: $global-weight-bold;
            color: map-get($foundation-palette, alert);
          }
        }
      }
    }
  }
}

;@include ---3m2zn08l3uw.load-css("sass-embedded-legacy-load-done:3768");