@use "sass:meta" as ---wv8raye81n;.main-v2-doska {
  margin-top: 2rem;
  padding-bottom: 2rem;

  h2 {
    font-size: rem-calc(30);
    margin-bottom: 1rem;

    @include breakpoint(small only) {
      font-size: rem-calc(26);
    }
  }

  .cell {
    .border {
      &-sell {
        padding-left: 20px;
        border-left: 10px solid $sell;
      }

      &-buy {
        padding-left: 20px;
        border-left: 10px solid $buy;
      }

      &-exchange {
        padding-left: 20px;
        border-left: 10px solid $exchange;
      }

      &-service {
        padding-left: 20px;
        border-left: 10px solid $service;
      }

      &-vacancy {
        padding-left: 20px;
        border-left: 10px solid $vacancy;
      }

      &-misc {
        padding-left: 20px;
        border-left: 10px solid $misc;
      }
    }

    .title {
      font-weight: $global-weight-bold;
      font-size: rem-calc(18);
    }

    .items {
      font-size: rem-calc(15);

      ul {
        margin-bottom: .7rem;
        margin-top: .7rem;

        a {
          margin: 0;
          -webkit-line-clamp: 1;
        }
      }
    }

    .count {
      font-weight: $global-weight-bold;
      font-size: rem-calc(14);
      color: $dark-gray;
    }
  }
}

;@include ---wv8raye81n.load-css("sass-embedded-legacy-load-done:3756");